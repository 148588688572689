import React from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import BreadcrumbArea from "../component/BreadcrumbArea";
import AboutSection from "../component/AboutSection";
import FeatureSection from "../component/FeatureSection";
// import FeatureSlider from "../component/FeatureSlider";
import FaqSection from "../component/FaqSection";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
    return(
        <>
            <Helmet>
                <title>{t('ListAnchor.AboutUs')} | SOS KSA</title>
                <meta name="description" content={t('meta.about')} />  
                <link rel="canonical" href="https://sos-ksa.com/about" />  
            </Helmet>
            <HeaderTopArea />
            <HeaderArea />
            <BreadcrumbArea />
            <AboutSection/>
            <FeatureSection/>
            {/* <FeatureSlider/> */}
            <FaqSection />
            <CtaSection />
            <FooterArea />
        </>
    );

};


export default About;