import React from 'react';
// import BreadcrumbAreaBg from "../assets/img/bread-bg.png";
import NavLinks from '../Data/NavLinks.json';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const getBasePath = () => {
    const pathName = window.location.pathname;
    const basePath = pathName.replace(/^\/(ar|en)\//, '/'); 
    return basePath;
}
const BreadcrumbArea = (props) => {
    const { t, i18n } = useTranslation();
    const Data = Object.entries(NavLinks);
    const Location = useLocation();

    // Function to get the path with the current language
    const getPathWithLang = (path) => {
        if (path.includes(':lang')) {
            return path.replace(':lang', i18n.language);
        }
        return path;
    };
    const BreadcrumbAreaBg = getBasePath + 'assets/img/bread-bg.png';

    // Find the current page data based on the location
    const currentPage = Data.find(value => getPathWithLang(value[1]['Path']) === Location.pathname);

    return (
        <div className="breadcrumb-area" style={{ backgroundImage: `url(${BreadcrumbAreaBg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-title">
                            {currentPage ? (
                                <>
                                    <h1 style={{ letterSpacing: i18n.language === "en" ? "1px" : "" }}>
                                        {t(`ListAnchor.${currentPage[1]['Text']}`)}
                                    </h1>
                                    <h6>
                                        <Link to={"/"}>{t('BreadcrumbArea.home')}</Link> / {t(`ListAnchor.${currentPage[1]['Text']}`)}
                                    </h6>
                                </>
                            ) : (
                                <h1>{t('BreadcrumbArea.default')}</h1>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreadcrumbArea;
