import React from 'react';
import { Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
const getBasePath = () => {
    const pathName = window.location.pathname;
    const basePath = pathName.replace(/^\/(ar|en)\//, '/'); 
    return basePath;
}
const BreadcrumbAreaPrivcy = (props) => {
    const { t, i18n } = useTranslation();

    // Function to get the path with the current language
    const getPathWithLang = (path) => {
        if (path.includes(':lang')) {
            return path.replace(':lang', i18n.language);
        }
        return path;
    };
    const BreadcrumbAreaBg = getBasePath + 'assets/img/bread-bg.png';

    return (
        <>
        <div className="breadcrumb-area" style={{ backgroundImage: `url(${BreadcrumbAreaBg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-title">
                        <h6>
                                        <Link to={"/"}>{t('BreadcrumbArea.home')}</Link> / {t(`FooterArea.privacyPolicy`)}
                                    </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
};

export default BreadcrumbAreaPrivcy;
