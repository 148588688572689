import React from 'react';
import { Link, useNavigate,useLocation  } from "react-router-dom";
import NavLinks from '../Data/NavLinks.json';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';

const ListAnchor = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const NavLinksArr = Object.entries(NavLinks);
    const location = useLocation();
    const changeLanguage = (lng) => {
        const currentPath = window.location.pathname;
        const newPath = getPathForLanguage(currentPath, lng);
        i18n.changeLanguage(lng).then(() => {
            if (newPath !== currentPath) { 
                navigate(newPath);
            }
        });
    };

    const getPath = (path) => {
        if (path.includes(':lang')) {
            return path.replace(':lang', i18n.language);
        }
        return path;
    };

    const getPathForLanguage = (path, lng) => {
        const pathParts = path.split('/');
        if (pathParts.length > 1 && ['en', 'ar'].includes(pathParts[1])) {
            pathParts[1] = lng; 
            return pathParts.join('/');
        }
       
        return path;
    };

    return (
        <>
            {NavLinksArr.map((value, index) => {
                const path = getPath(value[1]["Path"]);
                const isActive = location.pathname === path; 
                return (
                    <li key={index}>
                    <Link 
                        to={path} 
                        style={{ 
                            letterSpacing: i18n.language === "en" ? "2px" : "",
                            background: isActive ? 'linear-gradient(to right, red, darkred)' : 'none',
                            WebkitBackgroundClip: isActive ? 'text' : 'none',
                            WebkitTextFillColor: isActive ? 'transparent' : 'black',
                            fontWeight: isActive ? 'bold' : 'normal'
                        }}
                    >
                        {t('ListAnchor.' + value[1]["Text"])}
                    </Link>
                </li>
                );
            })}

            {i18n.language !== 'en' &&
                <li style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => changeLanguage('en')}>
                    <span style={{ fontSize: '18px', borderRadius: '50%', padding: '5px 10px', display: 'inline-block', color: 'black' }}>
                        English
                    </span>
                </li>
            }

            {i18n.language !== 'ar' &&
                <li style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => changeLanguage('ar')}>
                    <span style={{ fontSize: '18px', borderRadius: '50%', padding: '5px 10px', display: 'inline-block', color: 'black' }}>
                        العربية
                    </span>
                </li>
            }
        </>
    );
}

export default ListAnchor;
