import React from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import BreadcrumbArea from "../component/BreadcrumbArea";
import ContactSection from "../component/ContactSection";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';



const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('ListAnchor.ContactUs')} | SOS KSA</title>
                <meta name="description" content={t('meta.contact')} />  
                <link rel="canonical" href="https://sos-ksa.com/Contact" />  
            </Helmet>
            <HeaderTopArea />
            <HeaderArea />
            <BreadcrumbArea />
            <ContactSection />
            <CtaSection />
            <FooterArea />
        </>
    );
}


export default Contact;