import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";
import ProviderTermsConditionsSectionHeader from '../component/providerTermsConditionsSectionHeader';
import ProviderTermsConditionsContent from '../component/ProviderTermsConditionsContent';
import ProviderTermsHeader from '../component/Provider_Terms_header';
import { Helmet } from 'react-helmet-async';

const ProviderTerms = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        // Extract language code from the path
        const pathLang = location.pathname.split('/')[1];
        const supportedLanguages = ['ar', 'en']; // Define supported languages

        if (supportedLanguages.includes(pathLang)) {
            i18n.changeLanguage(pathLang);
        }
    }, [location.pathname, i18n]);

    return (
        <>
            <Helmet>
                <title>{t('ListAnchor.termsConditions')} | SOS KSA</title>
                {/* <meta name="description" content={t('meta.contact')} />   */}
                <link rel="canonical" href="https://sos-ksa.com/service-terms" />  
            </Helmet>
            <HeaderTopArea />
            <HeaderArea />
            <ProviderTermsHeader />
            <ProviderTermsConditionsSectionHeader />
            <ProviderTermsConditionsContent />
            <CtaSection />
            <FooterArea />
        </>
    );
};

export default ProviderTerms;
