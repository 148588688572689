
import React, { useState } from 'react';
import {Trans, useTranslation} from 'react-i18next';
// import Footer from '../assets/img/footer-bg.jpg';
// import LogoWhite from '../assets/img/logo-white.png';
import {Link} from "react-router-dom";
import NavLinks from "../Data/NavLinks.json";
import TermsAndConditions from "../component/TermsAndConditions"
import i18n from '../i18n';
const getBasePath = () => {
    const pathName = window.location.pathname;

    const basePath = pathName.replace(/\/(ar|en)?\/?.*$/, '/');

    return basePath;
};

const FooterArea = () => {
    const {t} = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleSubscribe = (e) => {
        e.preventDefault(); 
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };
    const Footer = getBasePath() + 'assets/img/footer-bg.jpg';
    const LogoWhite = getBasePath() + 'assets/img/logo-white.png';
    const NavLinksArr = Object.entries(NavLinks);
    const servicesData = t('Services.servicesData', {returnObjects: true})
    return (<div className="footer-area" data-background={Footer} style={{backgroundImage: `url(${Footer})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
                        <div className="footer-widget">
                            <div className="logo">
                                <Link to={NavLinks.HOME.Path}><img src={LogoWhite} alt="SOS" width={"width: 75%;"} /></Link>
                            </div>
                            <div className="contact-info-wrap">
                                <div className="single-contact-info">
                                    <div className="contact-icon">
                                        <i className="fa-solid fa-location-dot" style={{color: 'white'}}></i>
                                    </div>
                                    <div className="contact-details">
                                        <h6>{t('FooterArea.address')}</h6>
                                        <span>{t('Setting.address')}</span>
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                    <div className="contact-icon">
                                        <i className="fa-solid fa-phone" style={{color: 'white' }}></i>
                                    </div>
                                    <div className="contact-details">
                                        <h6>{t('FooterArea.phoneNumber')}</h6>
                                        <p>{t('Setting.phone')}</p>                                      
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                    <div className="contact-icon">
                                    <i className="fa-solid fa-envelope" style={{color: 'white' }}></i>
                                    </div>
                                    <div className="contact-details">
                                        <h6>{t('FooterArea.email')}</h6>
                                        <p>{t('Setting.email')}</p>                                     
                                    </div>
                                </div>
                           

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                        <div className="footer-widget">
                            <h5>{t('FooterArea.services')}</h5>
                            <div className="list-item">
                                <ul>
                                    {servicesData.slice(0, 7).map((value, index) => {
                                        return <li key={index} style={{color: '#fff', margin: '10px 0'}}><i
                                            className="las la-angle-double-right" style={{color: 'white' }}></i>{value.title}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-5 col-sm-5">
                    <div className="footer-widget">
                        <h5>{t('FooterArea.link')}</h5>
                            <div className="list-item">
                                <ul>
                                    {NavLinksArr.map((value, index) => {                    
                                        let path = value[1]["Path"];
                                        const pathParts = path.split('/');
                                        if (pathParts.length > 1 && [':lang'].includes(pathParts[1])) {
                                            pathParts[1] = i18n.language;
                                            path = pathParts.join('/');
                                        }
                                        return (
                                            <li key={index}>
                                                <Link 
                                                    to={path}
                                                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                                >
                                                    <i className="las la-angle-double-right" style={{color: 'white' }}></i>
                                                    {t('ListAnchor.' + value[1]["Text"])}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                      </div>
                    </div>


                    
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className="row">
                    <div className="col-xl-12">
                        <TermsAndConditions />
                    </div>
                </div>
                        <div className="footer-widget">
                        <h5>{t('FooterArea.newsletter')}</h5>
            <form className="newsletter-form" onSubmit={handleSubscribe}>
                <input 
                    type="text" 
                    placeholder={t('FooterArea.newsletterPlaceholder')}
                />
                <input 
                    type="submit" 
                    value={t('FooterArea.subscribe')} 
                />
            </form>
            {isDialogOpen && (
                <div className="dialog">
                    <div className="dialog-content">
                        <p>{t("Setting.soon")}</p>
                        <button onClick={handleCloseDialog}>Close</button>
                    </div>
                </div>
            )}
                        </div>
                    </div>
                </div>
               
                <div className="social-area mt-10 justify-content-center">
                <a href="https://www.facebook.com/saudisos" aria-label="SOS KSA على Facebook">
                <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/saudi_sos" aria-label="SOS KSA على Instagram">
                    <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://x.com/Saudi_sos" className="icon-link" aria-label="SOS KSA على X">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="10 10 600 500" 
                        className="icon"
                    >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                    </svg>
                </a>
                <a href="https://www.linkedin.com/in/roadside-assistance-services-company-36508a31b/" aria-label="SOS KSA على LinkedIn">
                    <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="https://www.snapchat.com/add/saudi_sos?src=QR_CODE" aria-label="SOS KSA على Snapchat">
                    <i className="fa-brands fa-snapchat"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCPPXh5nLW3pmWK0wkdStmxQ" aria-label="SOS KSA على YouTube">
                    <i className="fa-brands fa-youtube"></i>
                </a>
                <a href="https://www.tiktok.com/@saudi_sos" aria-label="SOS KSA على TikTok">
                    <i className="fa-brands fa-tiktok"></i>
                </a> 
                    </div>
                <div className="footer-bottom row mt-30 justify-content-center">
                <div className="col-lg-6 col-md-5 text-center">
                    <span> <Trans i18nKey={'FooterArea.rightsReserved'} components={{}}></Trans> </span>
                </div>
                  </div>
            </div>
        </div>);
}

export default FooterArea;
