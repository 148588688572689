import React from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/css/PrivacyTermsSectionHeader.css';

const PrivacyTermsSectionHeader = () => {
    const { t } = useTranslation();
    
    return (
        <div className="privacy-terms-section">
            <h2 className="privacy-title">{t('PrivacyTermsSectionHeader.privacy_policy_title')}</h2>
            <p className="privacy-description">
                {t('PrivacyTermsSectionHeader.privacy_policy_description')}
            </p>
        </div>
    );
};

export default PrivacyTermsSectionHeader;
