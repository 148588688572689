import React from 'react';
import '../assets/css/headerImage.css'; // تأكد من إضافة ملف CSS
import ProviderTermsHeaderImg from '../assets/img/hero-area-img.png';
const UserTermsHeader = () => {
  return (
    <div className="UsetImageheader" data-background={ProviderTermsHeaderImg}>
    
    </div>
  );
};

export default UserTermsHeader;
