import React from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/css/ProviderTermsConditionsSectionHeader.css';

const UserTermsConditionsSectionHeader = () => {
    const { t } = useTranslation();
    
    return (
        <div className="provider-terms-section">
            <h2 className="privacy-title">{t('UsertermsConditionsSectionHeader.terms_conditions_title')}</h2>
            <p className="privacy-description">
                {t('providertermsConditionsSectionHeader.terms_conditions_description')}
            </p>
        </div>
    );
};

export default UserTermsConditionsSectionHeader;
