import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppointmentBg from '../assets/img/appointment-bg.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

const AppointmentSection = () => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;

        // Basic validation
        const newErrors = {};
        if (!form.firstName.value) newErrors.firstName = t('validation.firstNameRequired');
        if (!form.lastName.value) newErrors.lastName = t('validation.lastNameRequired');
        if (!form.email.value) newErrors.email = t('validation.emailRequired');
        if (!form.phoneNumber.value) newErrors.phoneNumber = t('validation.phoneNumberRequired');
        if (!form.message.value) newErrors.message = t('validation.messageRequired');
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const serviceId = 'service_z2ou0gn';
        const templateId = 'template_an2h49t';
        const userId = 'AGBzo4GHjQvV-kj8Y';

        emailjs.sendForm(serviceId, templateId, form, userId)
            .then((result) => {
                console.log(result.text);
                toast.success(t('done_submit'));
                setFormValues({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    message: ''
                });
                setErrors({});
            }, (error) => {
                console.log(error.text);
                toast.error('error_submit');
            });
    };

    return (
        <div className="appointment-section pb-180">
            <div className="container">
                <div className="appointment-inner" data-background={AppointmentBg} style={{ backgroundImage: `url(${AppointmentBg})` }}>
                    <div className="row">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-title">
                                <h6 className="text-white wow fadeInUp animated" data-wow-delay="200ms">{t('AppointmentSection.title')}</h6>
                                <h2 className="text-white wow fadeInDown animated" data-wow-delay="400ms">{t('AppointmentSection.subtitle')}</h2>
                            </div>
                            <p className="text-white wow fadeInUp animated" data-wow-delay="200ms">{t('AppointmentSection.content')}</p>
                            <div className="contact-wrap">
                                <div className="icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-details">
                                    <h6>{t('AppointmentSection.contactTitle')}</h6>
                                    <p>{t('AppointmentSection.phoneNumber')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 wow fadeInDown animated" data-wow-delay="400ms">
                            <div className="apppointment-form-wrap white-bg">
                                <h2>{t('AppointmentSection.appointmentFormTitle')}</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input 
                                                type="text" 
                                                name="firstName" 
                                                value={formValues.firstName}
                                                onChange={(e) => setFormValues({ ...formValues, firstName: e.target.value })}
                                                placeholder={t('AppointmentSection.formFirstName')} 
                                                className={errors.firstName ? 'error' : ''}
                                            />
                                            {errors.firstName && <p className="error-message" style={{color:'red', fontSize:'14px', padding:'0',margin:'0'}}>{errors.firstName}</p>}
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                type="text" 
                                                name="lastName" 
                                                value={formValues.lastName}
                                                onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })}
                                                placeholder={t('AppointmentSection.formLastName')} 
                                                className={errors.lastName ? 'error' : ''}
                                            />
                                            {errors.lastName && <p className="error-message" style={{color:'red', fontSize:'14px', padding:'0',margin:'0'}}>{errors.lastName}</p>}
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                type="email" 
                                                name="email" 
                                                value={formValues.email}
                                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                                placeholder={t('AppointmentSection.formEmail')} 
                                                className={errors.email ? 'error' : ''}
                                            />
                                            {errors.email && <p className="error-message" style={{color:'red', fontSize:'14px', padding:'0',margin:'0'}}>{errors.email}</p>}
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                type="tel" 
                                                name="phoneNumber" 
                                                value={formValues.phoneNumber}
                                                onChange={(e) => setFormValues({ ...formValues, phoneNumber: e.target.value })}
                                                placeholder={t('AppointmentSection.formPhoneNumber')} 
                                                className={errors.phoneNumber ? 'error' : ''}
                                            />
                                            {errors.phoneNumber && <p className="error-message" style={{color:'red', fontSize:'14px', padding:'0',margin:'0'}}>{errors.phoneNumber}</p>}
                                        </div>
                                        <div className="col-md-12">
                                            <textarea 
                                                name="message" 
                                                id="message" 
                                                cols="30" 
                                                rows="10" 
                                                value={formValues.message}
                                                onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                                                placeholder={t('AppointmentSection.formMessage')}
                                                className={errors.message ? 'error' : ''}
                                            ></textarea>
                                            {errors.message && <p className="error-message" style={{color:'red', fontSize:'14px', padding:'0',margin:'0'}}>{errors.message}</p>}
                                        </div>
                                        <div className="col-md-12">
                                            <input type="submit" value={t('AppointmentSection.formSubmit')} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AppointmentSection;
