import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

const ContactSection = () => {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();

        const newErrors = {};
        if (!formValues.name) newErrors.name = t('validation2.NameRequired');
        if (!formValues.email) newErrors.email = t('validation2.emailRequired');
        if (!formValues.phoneNumber) newErrors.phoneNumber = t('validation2.phoneNumberRequired');
        if (!formValues.subject) newErrors.subject = t('validation2.Subject');
        if (!formValues.message) newErrors.message = t('validation2.messageRequired');
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const serviceId = 'service_z2ou0gn';
        const templateId = 'template_1zgf9al';
        const userId = 'AGBzo4GHjQvV-kj8Y';

        emailjs.send(serviceId, templateId, formValues, userId)
            .then((response) => {
                console.log(response);
                toast.success(t('done_submit'));
                setFormValues({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: ''
                });
                setErrors({});
            })
            .catch((error) => {
                console.log(error);
                toast.error(t('error_submit'));
            });
    };

    return (
        <div className="contact-page white-bg section-padding" style={{ padding: '30px', borderRadius: '10px' }}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="section-title">
                            <h6>{t('ContactSection.title')}</h6>
                            <h2>{t('ContactSection.subtitle')}</h2>
                        </div>
                        <div className="contact-form-wrap mt-40">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            placeholder={t('ContactSection.formName')}
                                            value={formValues.name}
                                            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                                            className={errors.name ? 'error' : ''}
                                        />
                                        {errors.name && <p className="error-message" style={{color: 'red', fontSize: '14px', padding: '0', margin: '0'}}>{errors.name}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            placeholder={t('ContactSection.formEmail')}
                                            value={formValues.email}
                                            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                            className={errors.email ? 'error' : ''}
                                        />
                                        {errors.email && <p className="error-message" style={{color: 'red', fontSize: '14px', padding: '0', margin: '0'}}>{errors.email}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="tel"
                                            placeholder={t('ContactSection.formPhoneNumber')}
                                            value={formValues.phoneNumber}
                                            onChange={(e) => setFormValues({ ...formValues, phoneNumber: e.target.value })}
                                            className={errors.phoneNumber ? 'error' : ''}
                                        />
                                        {errors.phoneNumber && <p className="error-message" style={{color: 'red', fontSize: '14px', padding: '0', margin: '0'}}>{errors.phoneNumber}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            placeholder={t('ContactSection.formSubject')}
                                            value={formValues.subject}
                                            onChange={(e) => setFormValues({ ...formValues, subject: e.target.value })}
                                            className={errors.subject ? 'error' : ''}
                                        />
                                        {errors.subject && <p className="error-message" style={{color: 'red', fontSize: '14px', padding: '0', margin: '0'}}>{errors.subject}</p>}
                                    </div>
                                    <div className="col-12">
                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="10"
                                            placeholder={t('ContactSection.formMessage')}
                                            value={formValues.message}
                                            onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                                            className={errors.message ? 'error' : ''}
                                        ></textarea>
                                        {errors.message && <p className="error-message" style={{color: 'red', fontSize: '14px', padding: '0', margin: '0'}}>{errors.message}</p>}
                                        <input type="submit" value={t('ContactSection.submitButtonText')} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="contact-info-wrap gray-bg" style={{ borderRadius: '30px' }}>
                            <h3>{t('ContactSection.contactInfoTitle')}</h3>
                            <p>{t('ContactSection.contactInfoDescription')}</p>
                            <div className="contact-info-inner mt-60">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-contact-info">
                                            <div className="info-icon">
                                            <a href="tel:920019000" className="info-link" aria-label="اتصل بنا على 920019000">
                                                <i className="fa-solid fa-phone"></i>
                                            </a>
                                            </div>
                                            <div className="info-content">
                                                <h4>{t('ContactSection.phoneTitle')}</h4>
                                                <p>{t('Setting.phone')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-contact-info">
                                            <div className="info-icon">
                                            <a href="mailto:support@sos-ksa.com" className="email-link" aria-label="أرسل بريدًا إلكترونيًا إلى support@sos-ksa.com">
                                                <i className="fa-solid fa-envelope"></i>
                                            </a>
                                            </div>
                                            <div className="info-content">
                                                <h4>{t('ContactSection.emailTitle')}</h4>
                                                <p>{t('Setting.email')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="single-contact-info">
                                            <div className="info-icon">
                                            <a href="https://maps.app.goo.gl/3yHUQe8i6g3DR1Yt9" aria-label="عرض موقعنا على خرائط جوجل">
                                                <i className="fa-solid fa-location-dot"></i>
                                            </a>
                                            </div>
                                            <div className="info-content">
                                                <h4>{t('ContactSection.locationTitle')}</h4>
                                                <p className="mb-10">{t('Setting.contrary')}</p>
                                                <p>{t('Setting.address')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="google-map mt-100">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d652.4032289705211!2d39.253755140070446!3d21.416967545063038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cb007e36d1cb%3A0xe27877e2a210d130!2zU09TIEtTQSDYtNix2YPYqSDYrtiv2YXYp9iqINin2YTZhdiz2KfYudiv2Kkg2LnZhNmJINin2YTYt9ix2YrZgg!5e0!3m2!1sen!2ssa!4v1725994404420!5m2!1sen!2ssa" width="600" height="600" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ContactSection;
