import React from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import BreadcrumbArea from "../component/BreadcrumbArea";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";
import FaqV2 from "../component/FaqV2";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Faq = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('ListAnchor.FAQ')} | SOS KSA</title>
                {/* <meta name="description" content={t('meta.contact')} />   */}
                <link rel="canonical" href="https://sos-ksa.com/faq" />  
            </Helmet>
            <HeaderTopArea />
            <HeaderArea />
            <BreadcrumbArea />
            <FaqV2 />
            <CtaSection />
            <FooterArea />
        </>
    );
}



export default Faq;