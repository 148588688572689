import React from 'react';
import '../assets/css/headerImage.css'; // تأكد من إضافة ملف CSS
import ProviderTermsHeaderImg from '../assets/img/hero-area-img.png';
const ProviderTermsHeader = () => {
  return (
    <div className="Imageheader" data-background={ProviderTermsHeaderImg}>
    
    </div>
  );
};

export default ProviderTermsHeader;
