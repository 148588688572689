import React, { useEffect } from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import BreadcrumbAreaPrivcy from "../component/BreadcrumbAreaPrivacy";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";
import PrivacyTermsSection from '../component/PrivacyTermsSection';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    const { lang } = useParams(); 
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    useEffect(() => {
        if (lang && ['en', 'ar'].includes(lang)) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    return (
        <>
            <Helmet>
                <title>{t('FooterArea.privacyPolicy')} | SOS KSA</title>
                {/* <meta name="description" content={t('meta.contact')} />   */}
                <link rel="canonical" href="https://sos-ksa.com/privacy" />  
            </Helmet>
            <HeaderTopArea />
            <HeaderArea />
            <BreadcrumbAreaPrivcy />
            <PrivacyTermsSection />
            <CtaSection />
            <FooterArea />
        </>
    );
};

export default PrivacyPolicy;
