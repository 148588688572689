import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneImage from '../assets/img/Group33.png'; 

const DownloadSection = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="download-section-container">
            <div 
                className={`download-section }`}
            >
                
                <div className="download-content wow fadeInRight animated" data-wow-delay="500ms">
                    <h2>{t('DownloadSection.title')}</h2>
                    <p>{t('DownloadSection.description')}</p>
                    <div className="download-buttons">
                        <a 
                            href="https://play.google.com/store/apps/details?id=com.sos.client&hl=en&pli=1" 
                            className="theme-btn mr-10"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-google-play"></i> {t('DownloadSection.downloadGooglePlay')}
                        </a>
                        <a 
                            href="https://apps.apple.com/sa/app/sos-ksa/id6476492035" 
                            className="theme-btn mr-10"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-app-store"></i> {t('DownloadSection.downloadAppStore')}
                        </a>
                    </div>
                </div>
                <div className="phone-image wow fadeInLeft animated" data-wow-delay="300ms">
                    <img src={PhoneImage} alt="Phone" />
                </div>
            </div>
        </div>
    );
}

export default DownloadSection;
