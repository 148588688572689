import React from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import BreadcrumbArea from "../component/BreadcrumbArea";
import ServiceSection from "../component/ServiceSection";
import FaqServiceSection from "../component/FaqServiceSection";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';


const Services = () => {
    const { t } = useTranslation();
    return (

        <>
            <Helmet>
                <title>{t('ListAnchor.Services')} | SOS KSA</title>
                <meta name="description" content={t('meta.services')} />  
                <link rel="canonical" href="https://sos-ksa.com/services" />  
            </Helmet>
            <HeaderTopArea />
            <HeaderArea />
            <BreadcrumbArea />
            <ServiceSection />
            <FaqServiceSection />
            <CtaSection />
            <FooterArea />

        </>
    );
}



export default Services;