// import React, { useState } from 'react';
import '../assets/css//PrivacyTermsSectionQuestions.css';
import { useTranslation } from 'react-i18next';

const PrivacyTermsSectionQuestions = () => {
    const { t , i18n} = useTranslation();
    // const [activeIndex, setActiveIndex] = useState(null);

    // const toggleAnswer = (index) => {
    //     setActiveIndex(activeIndex === index ? null : index);
    // };

    const questions = [
        {
            question: t('privacyQuestions.q1'),
            answer: t('privacyAnswers.a1')
        },
        {
            question: t('privacyQuestions.q2'),
            answer: t('privacyAnswers.a2')
        },
        {
            question: t('privacyQuestions.q3'),
            answer: t('privacyAnswers.a3')
        },
       
        {
            question: t('privacyQuestions.q6'),
            answer: t('privacyAnswers.a6')
        },
        {
            question: t('privacyQuestions.q5'),
            answer: t('privacyAnswers.a5')
        },
        {
            question: t('privacyQuestions.q4'),
            answer: t('privacyAnswers.a4')
        },
        {
            question: t('privacyQuestions.q7'),
            answer: t('privacyAnswers.a7')
        },
        {
            question: t('privacyQuestions.q8'),
            answer: t('privacyAnswers.a8')
        },
        {
            question: t('privacyQuestions.q9'),
            answer: t('privacyAnswers.a9')
        },
        {
            question: t('privacyQuestions.q10'),
            answer: t('privacyAnswers.a10')
        },
        {
            question: t('privacyQuestions.q11'),
            answer: t('privacyAnswers.a11')
        },
        {
            question: t('privacyQuestions.q12'),
            answer: t('privacyAnswers.a12')
        }

    ];

    // return (
    //     <div className="privacy-terms-section">
    //         {questions.map((item, index) => (
    //             <div key={index} className="privacy-question">
    //                 <div
    //                     className="question-title"
    //                     onClick={() => toggleAnswer(index)}
    //                 >
    //                     {item.question}
    //                 </div>
    //                 <div
    //                     className={`answer-text ${activeIndex === index ? 'active' : ''}`}
    //                 >
    //                     {item.answer}
    //                 </div>
    //             </div>
    //         ))}
    //     </div>
    // );
    return (
        <div className={`privacy-terms-section ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
            {questions.map((item, index) => (
                <div key={index} className="privacy-question">
                    <div className="question-title">
                        <div className="arrow-icon"></div>
                        {item.question}
                    </div>
                    <div className="answer-text active">
                        {item.answer}
                    </div>
                </div>
            ))}
        </div>
    );
    
    
        
    
};

export default PrivacyTermsSectionQuestions;
