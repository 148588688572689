import React from 'react';
import PrivacyTermsSectionHeader from './PrivacyTermsSectionheader';
import PrivacyTermsSectionQuestions from './PrivacyTermsSectionquestions';
function PrivacyTermsSection() {
  

  return (
    <div>
      <PrivacyTermsSectionHeader/>
      <PrivacyTermsSectionQuestions/>
    </div>
  );
}

export default PrivacyTermsSection;
