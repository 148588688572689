import {React} from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import HeroArea from "../component/HeroArea";
import AboutSection from "../component/AboutSection";
import ServiceSection from "../component/ServiceSection";
import VideoSection from "../component/VideoSection";
import AppointmentSection from "../component/AppointmentSection";
import FaqSection from "../component/FaqSection";
import CtaSection from "../component/CtaSection";
import FirstVisitPopup from "../component/FirstVisitPopup";
import FooterArea from "../component/FooterArea";
import DownloadSection from "../component/DownloadSection"
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import EadWebsiteSection from "../component/EadWebsiteSection";
const Home = () => {
    const { t } = useTranslation();
    
    const currentDate = new Date();

    const octoberFirst2024 = new Date(2024, 9, 1); 
    const isBeforeOctober = currentDate < octoberFirst2024;
    return (

        <>
            <Helmet>
                <title>{t('meta_title.home')}  | SOS KSA</title>
                <meta name="keywords" content={t('keywords')} />
                <meta name="description" content={t('meta.home')} />  
                <link rel="canonical" href="https://sos-ksa.com" />  
            </Helmet>
            <HeaderTopArea/>
            <HeaderArea/>
             <HeroArea/>
            {/* {isBeforeOctober ? <EadWebsiteSection /> : <HeroArea />} */}
            <AboutSection/>
            <FirstVisitPopup/>
            <DownloadSection/>
            <ServiceSection/>          
            <VideoSection/>
            <AppointmentSection/>
            <FaqSection/>
            <CtaSection/>
            <FooterArea/>
        </>


    );
};


export default Home;