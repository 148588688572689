import React, { useState, useEffect } from 'react';
import AlertImage from '../assets/img/alertImage.png';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/img/logo.png';
const FirstVisitPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
const {t}=useTranslation(); 
  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
    setShowPopup(true);
      localStorage.setItem('hasVisited', true);
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-left">
              <img src={AlertImage} alt="Promo" className="popup-image" />
            </div>
            <div className="spacer"></div>
            <div className="popup-right">
              <button className="close-button" onClick={handleClose}>×</button>
              <div className="logo-app-contaner">
              <img src={Logo} alt="Promo" className="logo-app_image" />
            </div>
                    {/* <h2>{t('DownloadSection.title')}</h2> */}
                    <p>{t('DownloadSection.description')}</p>
                    <div className="popup-download-buttons">
                        <a 
                            href="https://play.google.com/store/apps/details?id=com.sos.client&hl=en&pli=1" 
                            className="theme-btn mr-10"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-google-play"></i> {t('DownloadSection.downloadGooglePlay')}
                        </a>
                        <a 
                            href="https://apps.apple.com/sa/app/sos-ksa/id6476492035" 
                            className="theme-btn mr-10"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-app-store"></i> {t('DownloadSection.downloadAppStore')}
                        </a>
                    </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FirstVisitPopup;
