import React from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/css/ProviderTermsConditionsContant.css';

const SectionList = ({ titleKey, itemsKey, descriptionKey }) => {
    const { t } = useTranslation();
    const title = t(titleKey);
    const items = t(itemsKey, { returnObjects: true });
    const description = descriptionKey ? t(descriptionKey) : null;
    console.log(description);
    return (
        <div>
            <h3>{title}</h3>
            {description && <p>{description}</p>}
            <ol>
                {Object.values(items).map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ol>
        </div>
    );
};

const UserTermsConditionsContent = () => {
    const { t, i18n } = useTranslation();
    const terms = t('providertermsConditionsSection.terms', { returnObjects: true });
    const registrationInfo = t('usertermsConditionsSection.Information_User_By_User_When_Registering', { returnObjects: true });
    return (
        <div className="terms-conditions-table" dir={i18n.dir()}>
            <h3>{t('providertermsConditionsSection.title')}</h3>
            <p>{t('providertermsConditionsSection.subtitle')}</p>

            <table>
                <tbody>
                    {terms.map((termObj, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'row-white' : 'row-red'}>
                            <td>{termObj.term}</td>
                            <td>{termObj.definition}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3>{t('providertermsConditionsSection.Services.title')}</h3>
            <p>{t('providertermsConditionsSection.Services.subtitle')}</p>
            <SectionList
                titleKey="usertermsConditionsSection.Consent_and_Legal_Capacity.title"
                itemsKey="usertermsConditionsSection.Consent_and_Legal_Capacity.items"
                descriptionKey="usertermsConditionsSection.Consent_and_Legal_Capacity.subtitle"
            />

            <SectionList
                titleKey="providertermsConditionsSection.Legal_Nature_of_Agreement.title"
                itemsKey="providertermsConditionsSection.Legal_Nature_of_Agreement.items"
            />
             
            <h3> {t('providertermsConditionsSection.Digital_Signature.title')}</h3>
            <p>{t('providertermsConditionsSection.Digital_Signature.description')}</p>

            <h3>{t('providertermsConditionsSection.Information_Provided_By_User_When_Registering.title')}</h3>
            <p>{t('usertermsConditionsSection.Information_User_By_User_When_Registering.description')}</p>
            <div className="details-grid">
                {registrationInfo.details.map((detail, index) => (
                    <div key={index} className="detail-item">
                        {detail}
                    </div>
                ))}
            </div>
            <p>{t('usertermsConditionsSection.Information_User_By_User_When_Registering.subtitle')}</p>
            <div className="details-grid">
                {registrationInfo.details2.map((detail, index) => (
                    <div key={index} className="detail-item">
                        {detail}
                    </div>
                ))}
            </div>
            <p>{t('usertermsConditionsSection.Information_User_By_User_When_Registering.subtitle2')}</p>
            <div className="details-grid">
                {registrationInfo.details3.map((detail, index) => (
                    <div key={index} className="detail-item">
                        {detail}
                    </div>
                ))}
            </div>
            <ol>
                {Object.values(registrationInfo.items).map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ol>
            <SectionList
                titleKey="usertermsConditionsSection.Seventh.title"
                itemsKey="usertermsConditionsSection.Seventh.items"
            />
            <SectionList
                titleKey="usertermsConditionsSection.Restrictions.title"
                itemsKey="usertermsConditionsSection.Restrictions.items"
            />
            <SectionList
                titleKey="usertermsConditionsSection.PaymentPolicy.title"
                itemsKey="usertermsConditionsSection.PaymentPolicy.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.TermsandConditionsofEvaluations.title"
                itemsKey="providertermsConditionsSection.TermsandConditionsofEvaluations.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.PropertyRights.title"
                itemsKey="providertermsConditionsSection.PropertyRights.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.GrantedLicenses.title"
                itemsKey="providertermsConditionsSection.GrantedLicenses.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.Communication.title"
                itemsKey="providertermsConditionsSection.Communication.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.Notifications.title"
                itemsKey="providertermsConditionsSection.Notifications.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.TransferOfRightsAndObligations.title"
                itemsKey="providertermsConditionsSection.TransferOfRightsAndObligations.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.Amendments.title"
                itemsKey="providertermsConditionsSection.Amendments.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.AgreementTermination.title"
                itemsKey="providertermsConditionsSection.AgreementTermination.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.Liability.title"
                itemsKey="providertermsConditionsSection.Liability.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.Indemnification.title"
                itemsKey="providertermsConditionsSection.Indemnification.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.ApplicableLawAndCompetentCourts.title"
                itemsKey="providertermsConditionsSection.ApplicableLawAndCompetentCourts.items"
            />
            <SectionList
                titleKey="providertermsConditionsSection.TwentyFirstLanguage.title"
                itemsKey="providertermsConditionsSection.TwentyFirstLanguage.items"
            />
        </div>
    );
};

export default UserTermsConditionsContent;
