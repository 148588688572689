import React from 'react';
import { useLocation } from "react-router-dom";


const HeaderTopArea = () => {
 
    const location = useLocation();
    return (
        <div className={`header-top-area ${location.pathname !== '/' ? 'theme-bg' : 'secondary-bg'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8">
                        {/* <span><i className="fa-solid fa-envelope"></i>{t('Setting.email')}</span> */}
                        {/* <span><i className="fa-solid fa-location-dot"></i>{t('Setting.address')}</span>
                        <span><i className="fa-solid fa-clock"></i>{t('Setting.workingHours')}</span> */}
                    </div>
                
                </div>
            </div>
        </div>
    );
}

export default HeaderTopArea;
