import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
// import CtaBg from '../assets/img/cta-bg.jpg';
import {Link} from "react-router-dom";
import navLinks from "../Data/NavLinks.json";
const getBasePath = () => {
    const pathName = window.location.pathname;
    const basePath = pathName.replace(/\/(ar|en)?\/?.*$/, '/');
    return basePath;
};
const CtaSection = () => {
    const { t } = useTranslation();
    const CtaBg = getBasePath() + 'assets/img/cta-bg.jpg';
    return (
        <div className="cta-area">
            <div className="container">
                <div className="cta-inner" data-background={CtaBg} style={{ backgroundImage: `url(${CtaBg})` }}>
                    <h2 className="text-white wow fadeInUp animated" data-wow-delay="200ms"><Trans i18nKey="CtaSection.title" components={{ 1: <br /> }} /></h2>
                    <Link to={navLinks.CONTACT.Path} className="bordered-btn mt-30 wow fadeInDown animated" data-wow-delay="400ms">{t('CtaSection.buttonText')} <i className="fa-solid fa-arrow-right"></i></Link>
                </div>
            </div>
        </div>
    );
}

export default CtaSection;
