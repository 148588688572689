import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; 
import '../assets/css/TermsAndConditions.css';

const TermsAndConditions = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        window.scrollTo(0, 0);  
        navigate(path);         
    };

   
    const currentLanguage = i18n.language;

    const serviceTermsPath = `/${currentLanguage}/service-terms`;
    const customerTermsPath = `/${currentLanguage}/customer-terms`;
    const privacypolicyPath = `/${currentLanguage}/privacy`;
    const Faq = `/faq`;

    return (
        <div className="terms-and-conditions">
            <h2>{t('ListAnchor.termsConditions')}</h2>
            <div className="content">
                <div id="provider-terms" className="terms-section" onClick={() => handleNavigate(serviceTermsPath)}>
                    <h4><i className="las la-angle-double-right"></i>{t("termsConditions.termsConditionsType.providerTerms")}</h4>
                </div>
                
                <div id="user-terms" className="terms-section" onClick={() => handleNavigate(customerTermsPath)}>
                    <h4><i className="las la-angle-double-right"></i> {t("termsConditions.termsConditionsType.userTerms")}</h4>
                </div>
                <div id="privacy-policy" className="terms-section" onClick={() => handleNavigate(privacypolicyPath)}>
                    <h4><i className="las la-angle-double-right"></i> {t("FooterArea.privacyPolicy")}</h4>
                </div>
                {/* <div id="privacy-policy" className="terms-section" onClick={() => handleNavigate(Faq)}>
                    <h4><i className="las la-angle-double-right"></i> {t("ListAnchor.FAQ")}</h4>
                </div> */}
            </div>
        </div>
    );
};

export default TermsAndConditions;
